@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}